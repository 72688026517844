import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';

const SurveyFieldForm = ({
  defaultValues,
  onSubmit,
  addDefaultOptions = false
}) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  });

  const {
    fields: options,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'options'
  });

  useEffect(() => {
    if (addDefaultOptions) {
      append([{ option: '' }, { option: '' }], { shouldFocus: false });
    }
  }, [append, addDefaultOptions]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <FormControl id="field">
          <FormLabel>{t('common.title')}</FormLabel>
          <Input
            {...register('field', { required: true })}
            placeholder={t('placeholder.enter_field')}
            defaultValue={defaultValues?.field}
            autoFocus
          />
        </FormControl>

        {addDefaultOptions && (
          <>
            <FormControl id="options">
              <FormLabel>{t('common.options')}</FormLabel>
              <Stack spacing={4}>
                {options.map((field, index) => (
                  <HStack key={field.id}>
                    <Input
                      {...register(`options.${index}.option`, {
                        required: true
                      })}
                    />
                    <Button
                      variant="outline"
                      isDisabled={index < 2}
                      onClick={() => remove(index)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </HStack>
                ))}
              </Stack>
            </FormControl>
            <Button
              alignSelf="flex-start"
              variant="link"
              onClick={() => append({ option: '' })}
            >
              {t('workflow.add_option')}
            </Button>
          </>
        )}

        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default SurveyFieldForm;
