import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useInfiniteQuery } from 'react-query';
import { Center, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const AllTextAnswers = ({ field, AnswerListItem, AnswerDetailed }) => {
  const {
    isOpen: showSelectedAnswerModal,
    onOpen: openSelectedAnswerModal,
    onClose: closeSelectedAnswerModal
  } = useDisclosure();

  const [selectedAnswer, setSelectedAnswer] = useState({});

  const { t } = useTranslation();

  const {
    data: answers,
    status,
    fetchNextPage,
    isFetching,
    isSuccess,
    hasNextPage
  } = useInfiniteQuery(
    ['survey', 'answers', field.id],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/surveys/answers?field=${field.id}&limit=${20}&offset=${pageParam}`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      },
      enabled: true
    }
  );

  return (
    <>
      <Flex width="full">
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.cards').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            answers.pages[0].count > 0 ? (
              <Stack width="full">
                <InfiniteScrollHelper
                  hasMore={!isFetching && hasNextPage}
                  loadMore={fetchNextPage}
                >
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      992: 2,
                      768: 1
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-column"
                  >
                    {answers.pages.map(page =>
                      page.results.map(answer => (
                        <AnswerListItem
                          answer={answer}
                          onClick={() => {
                            setSelectedAnswer(answer);
                            openSelectedAnswerModal();
                          }}
                        />
                      ))
                    )}
                  </Masonry>
                </InfiniteScrollHelper>
                {isFetching && (
                  <Center height="30vh" width="full">
                    <LoadingIndicator centered />
                  </Center>
                )}
              </Stack>
            ) : null
          ) : null}
        </LoadingWrapper>
      </Flex>
      <ModalWrapper
        isOpen={showSelectedAnswerModal}
        onClose={closeSelectedAnswerModal}
        title={field.field}
        titleProps={{ fontSize: ['sm', 'md', null] }}
      >
        <AnswerDetailed answer={selectedAnswer} />
      </ModalWrapper>
    </>
  );
};

export default AllTextAnswers;
