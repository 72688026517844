import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  AvatarGroup,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingWrapper from 'components/LoadingWrapper';
import MemberAvatar from 'components/MemberAvatar';
import api from 'utils/api';
import AllTextAnswers from './AllTextAnswers';
import Description from 'components/Description';
import { format } from 'date-fns';

const AnswerListItem = ({ answer, onClick }) => {
  return (
    <Flex
      key={answer.id}
      flexDirection="column"
      justifyContent="space-between"
      cursor="pointer"
      rounded="lg"
      minHeight="220px"
      borderWidth={1}
      padding={6}
      onClick={onClick}
    >
      <Text noOfLines={5}>{answer.answer}</Text>
      <MemberAvatar member={answer?.creator} link={false} size="xs" />
    </Flex>
  );
};

const AnswerDetailed = ({ answer }) => {
  return (
    <Stack spacing={4} py={4}>
      <HStack alignItems="flex-start">
        <AvatarGroup size="sm" alignSelf="center">
          <MemberAvatar
            member={answer.creator}
            zIndex={-1}
            size="sm"
            src={
              answer.creator?.avatar
                ? answer.creator.avatar
                : answer.creator?.third_party_avatar
                ? answer.creator.third_party_avatar
                : null
            }
          />
        </AvatarGroup>
        <Stack spacing={0} justifyContent="flex-start">
          <Text fontSize="sm" fontWeight="bold">
            {answer.creator?.first_name}
          </Text>
          <Text fontSize="xs">
            {format(new Date(answer.created_at), 'dd MMM yyyy')}
          </Text>
        </Stack>
      </HStack>
      <Description>{answer ? answer.answer : ''}</Description>
    </Stack>
  );
};

const SurveyAnswer = ({ field }) => {
  const { t } = useTranslation();

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [limit] = useState(3);
  const [offset, setOffset] = useState(0);

  const modalBackgroundColor = useColorModeValue('white', 'gray.700');

  const {
    isOpen: showAnswerModal,
    onOpen: openAnswerModal,
    onClose: closeAnswerModal
  } = useDisclosure();

  const {
    isOpen: showAllAnswersModal,
    onOpen: openAllAnswersModal,
    onClose: closeAllAnswersModal
  } = useDisclosure();

  const {
    data: answers,
    status,
    isSuccess
  } = useQuery(['survey', 'answers', field.id, offset], async () => {
    const { data } = await api.get(
      `/surveys/answers?field=${field.id}&limit=${limit}&offset=${offset}`
    );
    return data;
  });

  return (
    <Box key={field.id} borderWidth={1} rounded="lg" padding={6}>
      <Stack spacing={8}>
        <Flex justifyContent="space-between">
          <Stack>
            <Heading fontSize="lg">{field.field}</Heading>
            <Text variant="muted">
              {t('survey.answer_count', { count: field.metrics.answers })}
            </Text>
          </Stack>
          <Badge variant="outline" alignSelf="flex-start">
            {t('survey.free_text')}
          </Badge>
        </Flex>
        <LoadingWrapper statuses={[status]}>
          {isSuccess && (
            <Stack spacing={8}>
              <SimpleGrid spacing={4} columns={[1, 2, 3]}>
                {answers.results.map(answer => (
                  <AnswerListItem
                    answer={answer}
                    onClick={() => {
                      setSelectedAnswer(answer);
                      openAnswerModal();
                    }}
                  />
                ))}
              </SimpleGrid>
              {answers.count > 0 && (
                <Flex alignItems="center" justifyContent="flex-end">
                  <HStack spacing={8}>
                    {field.metrics?.answers > 0 && (
                      <Text>
                        {t('common.item_number_out_of_total', {
                          item: t('common.answer_noun').toLowerCase(),
                          number: offset + 1,
                          total: field.metrics?.answers
                        })}
                      </Text>
                    )}
                    <Button variant="link" onClick={openAllAnswersModal}>
                      {t('button.see_all')}
                    </Button>
                    <ButtonGroup variant="outline">
                      <Button
                        isDisabled={!answers.previous}
                        onClick={() => {
                          setOffset(offset - limit);
                        }}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Button>
                      <Button
                        isDisabled={!answers.next}
                        onClick={() => {
                          setOffset(offset + limit);
                        }}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    </ButtonGroup>
                  </HStack>
                </Flex>
              )}
            </Stack>
          )}
        </LoadingWrapper>
      </Stack>
      <Modal isOpen={showAnswerModal} onClose={closeAnswerModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontSize={['sm', 'md', null]}>{field.field}</ModalHeader>
          <ModalBody>
            <AnswerDetailed answer={selectedAnswer} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size="full"
        isOpen={showAllAnswersModal}
        onClose={closeAllAnswersModal}
      >
        <ModalOverlay />
        <ModalContent position="relative" top={0}>
          <ModalBody pb={4}>
            <Container maxWidth="full" padding={[0, 'auto', 'auto']}>
              <Stack spacing={8}>
                <Flex
                  position="sticky"
                  top={0}
                  bg={modalBackgroundColor}
                  py={4}
                >
                  <Heading fontSize={['sm', 'md', null]}>{field.field}</Heading>
                  <ModalCloseButton />
                </Flex>
                <AllTextAnswers
                  field={field}
                  AnswerListItem={AnswerListItem}
                  AnswerDetailed={AnswerDetailed}
                />
              </Stack>
            </Container>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SurveyAnswer;
